import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Paper, { PaperProps } from "@material-ui/core/Paper"
import React from "react"
import {
  PageBuilderActiviteitTextFragment,
  PageBuilderErvaringTextFragment,
  PageBuilderNieuwsTextFragment,
  PageBuilderTextFragment,
  PageBuilderVacatureTextFragment,
} from "../../__generated__/graphql-gatsby"
import WpContent from "./wpContent"

export interface TextProps {
  block:
    | PageBuilderTextFragment
    | PageBuilderErvaringTextFragment
    | PageBuilderActiviteitTextFragment
    | PageBuilderNieuwsTextFragment
    | PageBuilderVacatureTextFragment

  className?: string | undefined
}

const Background: React.FC<PaperProps> = ({ ...props }) => (
  <Paper {...props} elevation={3} />
)

const Text: React.FC<TextProps> = ({ block, className }) => {
  const onPaper = block.instellingen?.achtergrond === "none"
  const maxWidth = (block.instellingen?.breedte as MaxWidthMuiKeys) || "md"

  if (!block.tekst) {
    return null
  }

  return (
    <Container maxWidth={maxWidth} className={className}>
      <Box
        component={onPaper ? "div" : Background}
        px={onPaper ? 0 : 3}
        py={onPaper ? 0 : 1}
        my={onPaper ? 0 : 3}
      >
        <WpContent dangerouslySetInnerHTML={{ __html: block.tekst }} />
      </Box>
    </Container>
  )
}

export default Text
